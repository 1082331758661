import React from "react";
import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";
import { StaticQuery, Link, graphql } from "gatsby";
import logo from "../../static/logos/logo.svg";

const Wrapper = styled("div")``;

const colorPrimary = "rgb(40, 41, 54)";

const ListLink = props => (
  <li
    css={css`
      display: inline-block;
      margin: 0 0.5em;
      font-weight: 600;
      line-height: 2em;
      a {
        border: none;
      }
    `}
  >
    <Link to={props.to}>{props.children}</Link>
  </li>
);

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Wrapper>
        <Global
          styles={css`
            html {
              line-height: 1; /* 1 */
              -webkit-text-size-adjust: 100%; /* 2 */
              overflow-x: hidden;
              font-size: 1em;
            }
            body {
              font-family: -apple-system, "BlinkMacSystemFont", "Segoe UI",
                "Roboto", "Helvetica Neue", "Arial", "Noto Sans", sans-serif;
              font-weight: 400;
              font-style: normal;
              font-display: swap;
              text-rendering: optimizeLegibility;
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
              -moz-font-feature-settings: "liga" on;
              line-height: 1;
              background-color: #fcf9f5;
              color: ${colorPrimary};
              transition: background-color 0.5s ease;
              margin: 0;
            }
            h1 {
              font-family: Didot, "Didot LT STD", "Hoefler Text", Garamond,
                "Times New Roman", Times, serif;
              font-weight: 300;
              margin-top: 0;
            }
            h2 {
              font-size: 0.875em;
            }
            a {
              color: ${colorPrimary};
              background-color: transparent;
              text-decoration: none;
            }
            p,
            li {
              line-height: 2em;
              a {
                padding-bottom: 1px;
                border-bottom: 1px solid ${colorPrimary};
              }
            }
            ul {
              list-style-type: none;
              padding: 0;
            }
            strong {
              font-weight: 600;
            }
          `}
        />
        <header
          css={css`
            position: fixed;
            left: 0;
            top: 0;
            right: 0;
            margin: auto;
            z-index: 5;
            padding: 5.5vh 9.333vw;
          `}
        >
          <Link
            css={css`
              width: 40px;
              float: left;
            `}
            to={"/"}
          >
            <img src={logo} alt={data.site.siteMetadata.title} />
          </Link>
          <nav>
            <ul
              css={css`
                list-style: none;
                float: right;
                padding: 0;
                margin: 0.6em 0;
                font-size: 0.875em;
                text-transform: uppercase;
                > li:last-of-type {
                  margin: 0 0 0 0.5em;
                }
              `}
            >
              <ListLink to="/">Features</ListLink>
              <ListLink to="/tauranga-wedding-venue-lazy-river-lodge">
                Venue
              </ListLink>
              <ListLink to="/info/">Info</ListLink>
            </ul>
          </nav>
        </header>
        <main>{children}</main>
        <footer
          css={css`
            padding: 70px 9.133vw;
            text-transform: uppercase;
            font-size: 0.875em;
          `}
        >
          <ul
            css={css`
              list-style: none;
              padding: 0;
              margin: 0;
              display: inline;
              > li:first-of-type {
                margin: 0 0.5em 0 0;
              }
            `}
          >
            <ListLink to="/">Features</ListLink>
            <ListLink to="/tauranga-wedding-venue-lazy-river-lodge">
              Venue
            </ListLink>
            <ListLink to="/info/">Info</ListLink>
          </ul>
          <p
            css={css`
              margin: 0;
              @media (min-width: 768px) {
                float: right;
                text-align: right;
              }
            `}
          >
            Built by{" "}
            <a
              href="https://instagram.com/glenmossong"
              title="WordPress Gatsby Developer NZ"
            >
              Glen Mossong
            </a>
          </p>
        </footer>
      </Wrapper>
    )}
  />
);
