import React from "react";
import { css } from "@emotion/core";
import Img from "gatsby-image";

const FeaturedImg = props => (
  <div
    css={css`
      @media (min-width: 768px) {
        width: 60%;
        height: 100vh;
        overflow: hidden;
      }
    `}
  >
    <Img
      css={css`
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      `}
      alt={props.title}
      fluid={props.fluid}
    />
  </div>
);

export default FeaturedImg;
