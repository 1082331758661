import React from "react";
import { css } from "@emotion/core";

const Header = props => (
  <div>
    <h1
      css={css`
        line-height: 16vw;
        font-size: 13.333vw;
        @media (min-width: 768px) {
          font-size: 6vw;
          line-height: 6vw;
        }
      `}
    >
      {props.title}
    </h1>
    <p>{props.description}</p>
  </div>
);

export default Header;
