import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header";
import FeaturedImg from "../components/featuredImg";
import ImgWithOrient from "../components/imageOrient";
import { css } from "@emotion/core";
import Helmet from "react-helmet";
import SEO from "../components/SEO";

export default ({ data }) => (
  <Layout>
    <Helmet>
      <title>Tauranga Wedding Florist Rosie from Rosies Wilderflower Co.</title>
    </Helmet>

    <SEO pagePath={"info"} pageSEO />

    <article>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <FeaturedImg
          alt="Rosie from Rosies Wildflowers"
          fluid={data.file.childImageSharp.fluid}
        />
        <div
          css={css`
            min-height: 45vh;
            margin: 30vw 0 15vw 0;
            padding: 6.5vw 9.333vw 0 9.333vw;
            @media (min-width: 768px) {
              display: flex;
              flex-direction: column;
              width: calc(60% - 2vw);
              margin: 20vw 0;
              padding: 0 9.333vw;
              position: relative;
            }
          `}
        >
          <Header
            title="Hello, I'm Rosie"
            description="I follow the seasons with my flowers and designs and prefer to work by colour palette using what is currently flourishing for the season."
          />
        </div>
      </div>

      <div
        css={css`
          .image {
            margin: 9.375vw 0;
          }
          @media (min-width: 768px) {
            max-width: 915px;
            margin: 12.5vw auto;
            padding: 0 8vw;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;

            .image {
              display: flex;
              justify-content: center;
              flex-direction: column;
              margin: 0 10px;

              &:not(:first-of-type) {
                margin: 9.375vw 10px 0 10px;
              }
            }

            .image.portrait {
              flex-basis: calc(50% - 20px);
            }

            .image.landscape {
              flex-basis: calc(100% - 20px);
            }

            .image.square {
              flex-basis: calc(60% - 40px);
              margin-left: 25%;
            }
          }
        `}
      >
        {data.allFile.edges.map(({ node }, i) => (
          <ImgWithOrient
            key={i}
            className="image"
            aspectRatio={node.childImageSharp.fluid.aspectRatio}
            alt={node.name}
            fluid={node.childImageSharp.fluid}
            offset={i}
          />
        ))}
      </div>

      <div
        css={css`
          min-height: 45vh;
          padding: 0 9.333vw;
          clear: both;
          max-width: 600px;
        `}
      >
        <h2
          css={css`
            text-transform: uppercase;
            font-weight: 600;
          `}
        >
          Rosies Wildflowers
        </h2>
        <div>
          <p>
            A move into a rural property on the outskirts of Tauranga has
            enabled me to begin growing many of the flowers I love to use in my
            wedding florals. We have been patiently establishing a{" "}
            <a
              href="https://lazyriverlodge.com"
              title="wedding venue with wildflower fields"
            >
              wedding venue with fields of wildflowers
            </a>{" "}
            which flower profusely over the summer, so that I can easily wander
            and freshly pick the colour palette of my brides’ choice to create
            the perfect bouquet.
          </p>
          <p>
            With towering trees planted many years ago along with lush more
            bushy foliage I am able to pick from the largest branch for an
            installation to the smallest twig for a tiny buttonhole all while
            remembering my clients’ brief.
          </p>
          <p>
            Our property has provided me with not only the luxury of having so
            much homegrown product I can forage for, but also a large airy
            workroom where we create the magic. It is a relaxed space that
            allows us freedom to spread out and gather from our grounds what we
            can find.
          </p>
          <p>
            For the{" "}
            <Link
              to="/tauranga-wedding-venue-lazy-river-lodge"
              title="Tauranga wedding venue"
            >
              weddings that are held here on the property
            </Link>
            , whether inside in the Atrium or outside by the lily ponds, the
            lazy river or in the meadow, it is a pleasure and privilege to use
            what nature has provided here to enhance the spaces that make every
            wedding unique.
          </p>
          <p>
            To chat, email me:{" "}
            <a href="mailto:hello@rosieswildflowerco.com">
              hello@rosieswildflowerco.com
            </a>
          </p>
        </div>
      </div>
    </article>
  </Layout>
);

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativeDirectory: { eq: "rosie" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 915, quality: 70) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    file(relativePath: { eq: "rosie-from-rosies-wildflowers.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 915, quality: 70) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
