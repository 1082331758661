import React from "react";
import Img from "gatsby-image";

// https://stackoverflow.com/questions/56832712/react-gatsbyjs-add-class-to-gatsby-image-based-on-aspect-ratio
// we only care about `aspectRatio`, the rest will be passed directly to `Img`
// also take out `className` so it be merged with our generated `orientation` class name
const ImgWithOrient = ({ aspectRatio, className, ...props }) => {
  let orientation;
  //let randomNumber;
  if (aspectRatio >= 1.2) orientation = "landscape";
  if (aspectRatio <= 0.8) orientation = "portrait";
  if (aspectRatio > 0.8 && aspectRatio < 1.2) orientation = "square";
  //randomNumber = (Math.random() * (1.0 - 2.6) + 2.6).toFixed(2);

  return (
    <div className={`${orientation} ${className}`}>
      <Img {...props} />
    </div>
  );
};

export default ImgWithOrient;
