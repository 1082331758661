module.exports = {
  siteTitle: "Rosies Wildflower Co.",
  siteTitleAlt: "Artisan floral studio, Bay of Plenty, NZ", // This allows an alternative site title for SEO schema.
  publisher: "Rosies Wildflower Co.", // Organization name used for SEO schema
  siteDescription:
    "An artisan wedding florist operating out of Lazy River Lodge wedding venue in the Bay of Plenty, New Zealand.",
  siteUrl: "https://rosieswildflowerco.com", // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomePage: 7, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 6, // Number of posts shown on paginated pages
  author: "Rosie", // Author for RSS author segment and SEO schema
  authorUrl: "https://rosieswildflowerco.com", // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: "", // Change for Twitter Cards
  shortTitle: "Rosies", // Used for App manifest e.g. Mobile Home Screen
  shareImage: "/src/images/rosie-from-rosies-wildflowers.jpg", // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 1360, // Change to the width of your default share image
  shareImageHeight: 1024, // Change to the height of your default share image
  siteLogo: "/logos/logo.svg", // Logo used for SEO
  siteIcon: "/logos/icon.png", // Icon used for favicon and App manifest
  backgroundColor: "#f7f0e9", // Used for Offline Manifest
  themeColor: "#f7444e", // Used for Offline Manifest
  copyright: "Copyright © Rosies Wildflower Co." // Copyright string for the RSS feed
};
